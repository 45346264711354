import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import * as _ from "lodash";
import { uiInfoService } from "../../../services";
import { scoreActions } from "../../../actions";

const initialTable = [
  "mtef",
  "proposed",
  "approved",
  "citizen",
  "quarterly",
  "procurement",
  "website",
];

const UpdateLeagueScore = ({ quarters, token }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    quarters.length > 0 ? quarters[0].period_id : null
  );
  const [sortedStates, setSortedStates] = useState([]);
  const [isCustomTable, setIsCustomTable] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState(null);
  const [currentEditRowIndex, setCurrentEditRowIndex] = useState(null);

  const dispatch = useDispatch();

  const fetchLeagueData = async (period_id) => {
    try {
      if (period_id) {
        const data = await uiInfoService.getPeriodByID(period_id);
        refreshSortedStates(data.period_id);
        if ([1, 2].includes(period_id)) {
          setIsCustomTable("initial_table");
        } else {
          setIsCustomTable(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLeagueData(selectedPeriod);
  }, [selectedPeriod, fetchLeagueData]);

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
      fontWeight: 700,
    },
  }));

  const sumLastColumn = (state) => {
    if (isCustomTable === "initial_table") {
      return initialTable.reduce(
        (sum, column) =>
          sum +
          (typeof state.data[column] === "number" ? state.data[column] : 0),
        0
      );
    } else {
      return (
        (Object.values(state.data).reduce(
          (sum, value) => sum + (typeof value === "number" ? value : 0),
          0
        ) /
          133) *
        100
      ).toFixed();
    }
  };

  const refreshSortedStates = (data) => {
    if (data && data.states) {
      const newSortedStates = data.states
        .slice()
        .sort((a, b) => sumLastColumn(b) - sumLastColumn(a));
      setSortedStates(newSortedStates);
    } else {
      setSortedStates([]);
    }
  };

  const getRowTextColor = (index) => {
    if (index < 9) {
      return "#000";
    } else if (index >= sortedStates.length - 3) {
      return "#000";
    } else {
      return "#000";
    }
  };

  const handleEditClick = (index, data) => {
    setCurrentEditRow(() => data);
    setCurrentEditRowIndex(() => index);
  };

  const handleSaveClick = async (value) => {
    const updatedData = {
      mtef: value.data.mtef,
      proposed: value.data.proposed,
      approved: value.data.approved,
      citizen: value.data.citizen,
      quarterly: value.data.quarterly,
      audit: value.data.audit,
      ag_report: value.data.ag_report,
      procurement: value.data.procurement,
      website: value.data.website,
    };

    if (!_.isEmpty(updatedData)) {
      dispatch(scoreActions.editScores(token, updatedData, value.data_id));
    }
  };

  const handleCellChange = (e, columnName) => {
    const newValue = e.target.value;
    const newData = { ...currentEditRow };
    newData.data[columnName] = Number(newValue);
    setCurrentEditRow(newData);
  };

  return (
    <Box marginTop={{ xs: "15%", sm: "2%" }}>
      <Typography
        variant="h3"
        color={"#049593"}
        align={"left"}
        fontWeight={700}
        gutterBottom
        marginBottom={{ xs: "15%", sm: "2%" }}
      >
        Edit Transparency League Table
      </Typography>
      <Box marginBottom={{ xs: "10%", sm: "2%" }}>
        <Typography
          variant={"subtitle2"}
          sx={{ marginBottom: 1 }}
          fontWeight={700}
          color={"#1C4E63"}
        >
          Select Quarter
        </Typography>
        <Formik
          initialValues={{ states: sortedStates }}
          onSubmit={handleSaveClick}
        >
          {(props) => (
            <Form>
              <Box
                width={{ xs: "50%", sm: "25%" }}
                marginLeft={{ xs: "0%", sm: "0%" }}
                marginBottom={{ xs: "0%", sm: "3%" }}
              >
                <FormControl fullWidth>
                  <Select
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    fullWidth
                  >
                    {quarters.map((periodId) => (
                      <MenuItem
                        key={periodId.period_id}
                        value={periodId.period_id}
                      >
                        {periodId.quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box marginBottom={"2%"}>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Edit the scores of one State at a time
                </Typography>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Save the edit of a State before starting another edit
                </Typography>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Save all the edits in a quarter before switching to another
                  quarter
                </Typography>
              </Box>
              {isCustomTable === "initial_table" ? (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">S/N</StyledTableCell>
                        <StyledTableCell align="left">
                          Name of States
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          MTEF (12)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Proposed Budget (13)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Approved Budget (21)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Citizens' Budget (15)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Quarterly BIR (16)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          e-Procurement Portal (11)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          State Website with Fiscal Data Repository (12)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Score (%)
                        </StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedStates.length === 0 ? (
                        <TableRow>
                          <StyledTableCell align="left" colSpan={10}>
                            <Typography
                              variant="h4"
                              color={"#049593"}
                              align="center"
                              fontWeight={700}
                            >
                              No League Score
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        sortedStates.map((state, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: "#eee",
                            }}
                          >
                            <TableCell
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {state.state_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.mtef}
                                  name={`states.${index}.mtef`}
                                  as={TextField}
                                  onChange={(e) => handleCellChange(e, "mtef")}
                                  InputProps={{
                                    inputProps: { min: 0, max: 12 },
                                  }}
                                  fullWidth
                                />
                              ) : (
                                state.data.mtef
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.proposed}
                                  name={`states.${index}.proposed`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "proposed")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.proposed
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.approved}
                                  name={`states.${index}.approved`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "approved")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.approved
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.citizen}
                                  name={`states.${index}.citizen`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "citizen")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.citizen
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.quarterly}
                                  name={`states.${index}.quarterly`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "quarterly")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.quarterly
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.procurement}
                                  name={`states.${index}.procurement`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "procurement")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.procurement
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.website}
                                  name={`states.${index}.website`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "website")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.website
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {sumLastColumn(state)}
                            </TableCell>
                            <TableCell align="center" style={{ color: "#000" }}>
                              {currentEditRowIndex === index ? (
                                <Button
                                  onClick={() =>
                                    handleSaveClick(
                                      currentEditRow,
                                      state.state_id
                                    )
                                  }
                                  sx={{ color: "#280274", fontWeight: 700 }}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => handleEditClick(index, state)}
                                  sx={{ color: "#B80000", fontWeight: 700 }}
                                >
                                  Edit
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">S/N</StyledTableCell>
                        <StyledTableCell align="left">
                          Name of States
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          MTEF (12)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Proposed Budget (13)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Approved Budget (21)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Citizens' Budget (15)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Quarterly BIR (16)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Audit Report (18)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Accountant General’s Report (15)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          e-Procurement Portal (11)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          State Website with Fiscal Data (12)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Score (%)
                        </StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedStates.length === 0 ? (
                        <TableRow>
                          <StyledTableCell align="left" colSpan={10}>
                            <Typography
                              variant="h4"
                              color={"#049593"}
                              align="center"
                              fontWeight={700}
                            >
                              No League Score
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        sortedStates.map((state, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: "#eee",
                            }}
                          >
                            <TableCell
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {state.state_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.mtef}
                                  name={`states.${index}.mtef`}
                                  as={TextField}
                                  onChange={(e) => handleCellChange(e, "mtef")}
                                  InputProps={{
                                    inputProps: { min: 0, max: 12 },
                                  }}
                                  fullWidth
                                />
                              ) : (
                                state.data.mtef
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.proposed}
                                  name={`states.${index}.proposed`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "proposed")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.proposed
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.approved}
                                  name={`states.${index}.approved`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "approved")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.approved
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.citizen}
                                  name={`states.${index}.citizen`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "citizen")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.citizen
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.quarterly}
                                  name={`states.${index}.quarterly`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "quarterly")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.quarterly
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.audit}
                                  name={`states.${index}.audit`}
                                  as={TextField}
                                  onChange={(e) => handleCellChange(e, "audit")}
                                  fullWidth
                                />
                              ) : (
                                state.data.audit
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.ag_report}
                                  name={`states.${index}.ag_report`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "ag_report")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.ag_report
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.procurement}
                                  name={`states.${index}.procurement`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "procurement")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.procurement
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {currentEditRowIndex === index ? (
                                <Field
                                  value={currentEditRow.data.website}
                                  name={`states.${index}.website`}
                                  as={TextField}
                                  onChange={(e) =>
                                    handleCellChange(e, "website")
                                  }
                                  fullWidth
                                />
                              ) : (
                                state.data.website
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                color: getRowTextColor(index),
                                fontWeight: 600,
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {sumLastColumn(state)}
                            </TableCell>
                            <TableCell align="center">
                              {currentEditRowIndex === index ? (
                                <Button
                                  onClick={() =>
                                    handleSaveClick(
                                      currentEditRow,
                                      state.state_id
                                    )
                                  }
                                  sx={{ color: "#280274", fontWeight: 700 }}
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => handleEditClick(index, state)}
                                  sx={{ color: "#B80000", fontWeight: 700 }}
                                >
                                  Edit
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default UpdateLeagueScore;
