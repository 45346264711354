import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Field, Form, Formik, FastField } from "formik";
import { useDispatch } from "react-redux";
import { scoreActions } from "../../../actions";
import * as _ from "lodash";
import * as yup from "yup";

const validationSchema = yup.object({
  mtef: yup
    .number()
    .required("Please enter MTEF Score")
    .min(0, "MTEF Score must be at least 0")
    .max(12, "MTEF Score must be at most 12"),

  proposed: yup
    .number()
    .required("Please enter Proposed Budget Score")
    .min(0, "Proposed Budget Score must be at least 0")
    .max(12, "Proposed Budget Score must be at most 12"),

  approved: yup
    .number()
    .required("Please enter Approved Budget Score")
    .min(0, "Approved Budget Score must be at least 0")
    .max(12, "Approved Budget Score must be at most 12"),

  citizen: yup
    .number()
    .required("Please enter Citizens' Budget Score")
    .min(0, "Citizens' Budget Score must be at least 0")
    .max(12, "Citizens' Budget Score must be at most 12"),

  quarterly: yup
    .number()
    .required("Please enter Quarterly BIR Score")
    .min(0, "Quarterly BIR Score must be at least 0")
    .max(12, "Quarterly BIR Score must be at most 12"),

  procurement: yup
    .number()
    .required("Please enter e-Procurement Portal Score")
    .min(0, "e-Procurement Portal Score must be at least 0")
    .max(12, "e-Procurement Portal Score must be at most 12"),

  website: yup
    .number()
    .required("Please enter State Website Score")
    .min(0, "State Website Score must be at least 0")
    .max(12, "State Website Score must be at most 12"),
});

const LeagueForm = ({ quarters, states, token }) => {
  const loading = "";

  const dispatch = useDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(quarters.period_id);

  const initialValues = {
    states: states.reduce(
      (acc, { state_id, state_name }) => ({
        ...acc,
        [state_id]: {
          id: state_id,
          name: state_name,
          mtef: 0,
          proposed: 0,
          approved: 0,
          citizen: 0,
          quarterly: 0,
          audit: 0,
          ag_report: 0,
          procurement: 0,
          website: 0,
        },
      }),
      {}
    ),
  };

  const calculateStateScore = (stateValues) => {
    const num1 = parseFloat(stateValues.mtef) || 0;
    const num2 = parseFloat(stateValues.proposed) || 0;
    const num3 = parseFloat(stateValues.approved) || 0;
    const num4 = parseFloat(stateValues.citizen) || 0;
    const num5 = parseFloat(stateValues.quarterly) || 0;
    const num6 = parseFloat(stateValues.audit) || 0;
    const num7 = parseFloat(stateValues.ag_report) || 0;
    const num8 = parseFloat(stateValues.procurement) || 0;
    const num9 = parseFloat(stateValues.website) || 0;

    const result = (
      ((num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9) / 133) *
      100
    ).toFixed(0);
    return result;
  };

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const ColorButton = styled(Button)(() => ({
    color: "#fff",
    width: 140,
    height: 40,
    fontSize: "14px",
    textTransform: "capitalize",
    backgroundColor: "#049593",
    borderColor: "#049593",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1C4E63",
      borderColor: "#1C4E63",
    },
  }));

  const handleSubmit = (values) => {
    const newStateData = Object.values(values.states).map((state) => {
      return {
        mtef: state.mtef,
        proposed: state.proposed,
        approved: state.approved,
        citizen: state.citizen,
        quarterly: state.quarterly,
        audit: state.audit,
        ag_report: state.ag_report,
        procurement: state.procurement,
        website: state.website,
        period_id: selectedPeriod,
        state_id: state.id,
      };
    });

    if (!_.isEmpty(newStateData)) {
      dispatch(scoreActions.newLeagueScore(token, newStateData));
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <Box>
      <Box>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          gutterBottom
          marginBottom={{ xs: "15%", sm: "2%" }}
        >
          Add New Quarter Scores
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ marginBottom: 1 }}
          fontWeight={700}
          color={"#1C4E63"}
        >
          Select Quarter
        </Typography>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Box
                marginBottom={"2%"}
                width={"25%"}
                marginLeft={{ xs: "0%", sm: "0%" }}
              >
                <FormControl required fullWidth>
                  <Select
                    labelId="quarter"
                    id="quarter"
                    name={"quarter"}
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    fullWidth
                  >
                    {quarters.map((periodId) => (
                      <MenuItem key={periodId} value={periodId.period_id}>
                        {periodId.quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TableContainer component={Paper}>
                <Table marginTop={"2%"}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" fontWeight={700}>
                        States
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        MTEF Framework (12)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Proposed Budget (13)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Approved Budget (21)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Citizens' Budget (15)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Quarterly BIR (16)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Audit Report (18)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Accountant General's Report (15)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        e-Procurement Portal (11)
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        State Website (12)
                      </StyledTableCell>
                      <StyledTableCell align="left">Score (%)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(props.values.states).map((state) => (
                      <StyledTableRow key={state.id}>
                        <TableCell>
                          <FormControl required fullWidth>
                            <Select
                              labelId={`state-${state.id}`}
                              id={`state-${state.id}`}
                              name={`state-${state.id}`}
                              fullWidth
                              value={state.id}
                              inputProps={{ readOnly: true }}
                            >
                              <MenuItem value={state.id}>{state.name}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FastField
                            name={`states.${state.id}.mtef`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 12 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FastField
                            name={`states.${state.id}.proposed`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 13 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FastField
                            name={`states.${state.id}.approved`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 21 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.citizen`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 15 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.quarterly`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 16 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.audit`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 18 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.ag_report`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 15 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.procurement`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 11 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`states.${state.id}.website`}
                            as={TextField}
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 12 } }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={calculateStateScore(state)}
                            InputProps={{
                              readOnly: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box
                  margin={"2% 0%"}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <ColorButton
                    type={"submit"}
                    loading={loading}
                    variant="outlined"
                    size="medium"
                  >
                    Submit
                  </ColorButton>
                </Box>
              </TableContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default LeagueForm;
